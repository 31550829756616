import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { baseUrl } from '../config/Constants';
import { getToken } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Select } from 'antd';

export default function AdminShipmentList() {
 
  const [shipmentList, setShipmentList] = useState([]);
  const [shipmentFilteredList, setShipmentFilteredList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [editedShippingFee, setEditedShippingFee] = useState('');
  const [minimumShippingAmount, setMinimumShippingAmount] = useState('');
  const [activeRowId, setActiveRowId] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districts, setDistrict] = useState(["Aliağa","Balçova","Bayındır","Bayraklı","Bergama","Beydağ",
    "Bornova","Buca", "Çeşme", "Çiğli", "Dikili", "Foça", "Gaziemir", "Güzelbahçe", 
    "Karabağlar", "Karaburun", "Karşıyaka", "Kemalpaşa", "Kınık", "Kiraz",
    "Konak", "Menderes", "Menemen", "Narlıdere", "Ödemiş", "Seferihisar", 
    "Selçuk", "Tire", "Torbalı" , "Urla"
  ]); 

  const districtOptions = [{ label: 'Tümü', value: "all" }, ...districts.map(item => ({ label: item, value: item }))];

  let token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    fetchShipmentList();
  }, []);

  const fetchShipmentList = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(baseUrl+`api/Shipment/GetAllShipment`, requestOptions);
      const data = await response.json();
      const shipmentData= data.data;

      setShipmentList(shipmentData); 
      setShipmentFilteredList(shipmentData) 
    } catch (error) {
     // console.error(error);
    }
  };

  function showToastSuccess(message, duration = 2000) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  const handleSaveClick = (shipment) => {
    if(shipment.id===0)
    {
      const addShipment = {
        shippingFee:editedShippingFee ?? 0,
        minimumShippingAmount:minimumShippingAmount ?? 0,
        districtId: shipment.districtId,
        quarterId: shipment.quarterId,
        sameDayShipping: true,
        isShow:shipment.isShow
      };
      const token = getToken();
      fetch(baseUrl + "api/Shipment/CreateShipment", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(addShipment),
      })
      .then((response) => response.json())
      .then((data) => {
        if(data.success)
        {
          showToastSuccess('Değişiklikler başarıyla kaydedilmiştir.');
          fetchShipmentList();
        }
        else{
          toast.error(data.message??"Bilinmeyen bir hata ile karşılaşıldı", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
      }
      else{
        const updateShipment = {
          id: shipment.id,
          districtId: shipment.districtId,
          quarterId: shipment.quarterId,
          shippingFee:editedShippingFee ?? 0,
          minimumShippingAmount:minimumShippingAmount ?? 0,
          sameDayShipping: true,
          isShow:shipment.isShow
        }; 

      const token = getToken();
      fetch(baseUrl+"api/Shipment/UpdateShipment", {
      method: "PUT",
      headers: {
        "Authorization":  `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateShipment),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.success)
        {
        showToastSuccess('Değişiklikler başarıyla kaydedilmiştir.');
        fetchShipmentList();}
        else{
          toast.error(data.message??"Bilinmeyen bir hata ile karşılaşıldı", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
      }
      fetchShipmentList();
      setEditMode(false);
      setActiveRowId(null);
  };

  const handleEditClick = (shipmentId) => {
    setEditMode(true);
    setActiveRowId(shipmentId);
    const editedShipment = shipmentList.find((shipment) => shipment.quarterId === shipmentId);
    setEditedShippingFee(editedShipment.shippingFee);
    setMinimumShippingAmount(editedShipment.minimumShippingAmount);
  };

  const onChangeFilterDistrict = (value) => {
    // console.log(`selected ${value}`);
    setSelectedDistrict(value)
  };

  const onFilterClick = async () => {
      try {
        if (selectedDistrict && selectedDistrict !== "all") {
          const filteredShipmentList = shipmentList.filter(item => item.districtName === selectedDistrict );
          setShipmentFilteredList(filteredShipmentList) 
        } 
        else {
          fetchShipmentList();
        }
      } catch (error) {
        console.error('Hata oluştu:', error);
      }
  };

  return (
    <div  style={{ margin: "50px" }}>
      <ToastContainer />
      <h1  className='baslik'>MJ Çiçek</h1>
      {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}
      <div style={{marginTop:"50px"}}>
      <div style={{ textAlign: "left",  display:"flex"}}>
         <div style={{marginTop:"5px",marginRight:"15px" }}>İlçe:</div>
             <Select
                onChange={onChangeFilterDistrict}
                placeholder="İlçe Seçiniz"
                style={{ width:"150px"}}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={districtOptions}
                className="input-style"
              />
              <Button onClick={onFilterClick}>Filtrele</Button>
          </div>

          {shipmentFilteredList && (
          <table className='table table-light'>
          <thead>
          </thead>
          <tbody>
            {shipmentFilteredList.map((shipment) => ( 
              <React.Fragment key={shipment.quarterId}>
                <tr style={{ borderBottom: "1px solid #ccc"}}>
                  <td style={{verticalAlign:"middle",width:"0px"}}>        
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    defaultChecked={shipment.isShow}
                    id={`kategori${shipment.quarterId}`}
                    style={{ marginRight: "5px", transform: "scale(1.5)" }}
                    disabled={!editMode || (activeRowId !== shipment.quarterId)}
                    onChange={(e) => {
                      const updatedShipmentList = shipmentList.map((item) =>
                        item.quarterId === shipment.quarterId
                          ? { ...item, isShow: !item.isShow }
                          : item
                      );
                      setShipmentList(updatedShipmentList);
                    }}
                  />
                  </td>
                  <td style={{verticalAlign:"middle"}}>{shipment.quarterName}</td>
                  <td style={{verticalAlign:"middle"}}>{shipment.districtName}</td> 
                  <td style={{ verticalAlign: "middle" }}> Kargo Tutarı :  
                  {editMode && (activeRowId === shipment.quarterId) ? (
                            <input
                              type="text"
                              value={editedShippingFee}
                              style={{marginLeft:"10px"}}
                              onChange={(e) => setEditedShippingFee(e.target.value)}
                            />
                          ) : (
                            ` ${shipment.shippingFee} TL`
                          )}
                  </td>
                  <td style={{ verticalAlign: "middle" }}> Minimum Tutar :  
                  {editMode && activeRowId === shipment.quarterId ? (
                            <input
                              type="text"
                              value={minimumShippingAmount}
                              style={{marginLeft:"10px"}}
                              onChange={(e) => setMinimumShippingAmount(e.target.value)}
                            />
                          ) : (
                            ` ${shipment.minimumShippingAmount} TL`
                          )}
                  </td>              
                  <td style={{ verticalAlign: "middle" }}>
                        {editMode && activeRowId === shipment.quarterId ? (
                            <button
                              onClick={() => handleSaveClick(shipment)}
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0 10px",
                                borderRadius: "5px",
                                backgroundColor: "#893694",
                                border: "1px solid gray",
                                boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
                                right: "20px",
                                zIndex: "999",
                                float: "right",
                                marginTop: "50px",
                                fontStyle: "italic",
                              }}
                            >
                              Kaydet
                            </button>
                          ) : (
                            <button
                              style={{
                                height: "40px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "0 10px",
                                borderRadius: "5px",
                                backgroundColor: "#893694",
                                border: "1px solid gray",
                                boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
                                right: "20px",
                                zIndex: "999",
                                float: "right",
                                marginTop: "50px",
                                fontStyle: "italic",
                              }}
                              onClick={() => handleEditClick(shipment.quarterId)}
                            >
                              <i className="fas fa-edit" style={{ marginRight: "5px" }}></i>Düzenle
                            </button>
                          )}
                  </td>
                </tr>
                
              </React.Fragment>
            ))}      
          </tbody>
        </table>
          )}
      </div>
    </div>
  )}