import React, { useState ,useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { getToken,getEmail } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from '../config/Constants';
import 'react-toastify/dist/ReactToastify.css';
import { getFirestore, collection, getDocs,doc,getDoc,setDoc,addDoc,query,where  } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import axios from 'axios';

function AddSms() {
  const [description, setDescription] = useState("");
  const [phoneNumber, setPhoneNumber] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      const firestore = getFirestore();
  
      try {
        const usersCollection = collection(firestore, 'users');
        const usersSnapshot = await getDocs(usersCollection);
  
        const phoneNumbersArray = [];
  
        usersSnapshot.forEach((doc) => {
          const userData = doc.data();
          const phoneNumber = userData.phoneNumber;
  
          if (phoneNumber) {
            console.log("jnjjn,",phoneNumber)
            phoneNumbersArray.push(phoneNumber);
          }
        });
        setPhoneNumber(phoneNumbersArray);

  
        console.log('Phone Numbers:', phoneNumbersArray);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();    
  }, []);
  
  function showToastSuccess(message, duration = 2000) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  function showToastError(message, duration = 3000) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  //listedeki herkese gider
 /* 
  const sendSoapRequest = async (description) => {
    console.log("fonk dğeişti günellendi")
    console.log("user phoness", phoneNumber)

    const baseUrl = "https://webservice.asistiletisim.com.tr/get/sendSms.aspx";
    const queryParams = {
        Username: "appelier.demo",
        UserCode: "7298",
        AccountId: "3909",
        MessageText: description,
        Originator: "ASIST TEST",
        Password: "AsfdgyQ*7D",
        ReceiverList: phoneNumber,
        SendDate: "",
        ValidityPeriod: "60"
    };

    const url = `${baseUrl}?${new URLSearchParams(queryParams).toString()}`;

    try {
        const response = await axios.get(url);

        console.log("jj", response.data);
    } catch (error) {
        console.error('Error making GET request:', error.message);
    }
    
    
};
*/
  
//sadece senaya sms

const sendSoapRequest = async (description) => {
  console.log("fonk dğeişti günellendi")
  console.log("user phoness", phoneNumber)

  const baseUrl = "https://webservice.asistiletisim.com.tr/get/sendSms.aspx";
  const queryParams = {
      Username: "appelier.demo",
      UserCode: "7298",
      AccountId: "3909",
      MessageText: description,
      Originator: "ASIST TEST",
      Password: "AsfdgyQ*7D",
      ReceiverList: "5556672587",
      SendDate: "",
      ValidityPeriod: "60"
  };

  const url = `${baseUrl}?${new URLSearchParams(queryParams).toString()}`;

  try {
      const response = await axios.get(url);

      console.log("jj", response.data);
  } catch (error) {
      console.error('Error making GET request:', error.message);
  }
  
};


  const sendSms = async ( description) => {
    if (description.length > 120) {
      toast.error('Metin maksimum 120 karakter olmalı.',{
        position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      });
      return;
    }
  
    const data = {
      text: description,
    };  
    const token = getToken();
  
    const firestore = getFirestore();
    const smsCollection = collection(firestore, 'smslist');
    const docRef = await addDoc(smsCollection, data);
    if (docRef.id) {
      showToastSuccess('Sms başarıyla gönderilmiştir.');
      setDescription("");
      sendSoapRequest(description);
      const res = await axios.get("https://api.ipify.org/?format=json");
      console.log(res.data.ip);

      /*
      setTimeout(() => {
        navigate("/adminsmslist");
      }, 2000);
      */
    } else {
      showToastError("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
    }
    
  };


  const handleGoBack = () => {
    navigate(-1); 
  };

  return (
    <>
    <ToastContainer />
            {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}


        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "400px" }}>
            <h1 className='baslik' style={{ textAlign: "center" }}>MJ Çiçek</h1>
           
    
        <div style={{ display: "vertical", marginTop:"100px",justifyContent: "center", alignItems: "center", height: "100vh" }}>
      
        <div style={{ marginBottom: "20px" }}>
          <textarea
            className="contentinput"
            value={description}
            onChange={(e) => setDescription(e.target.value)} 
            placeholder="Sms içeriğini giriniz."
            style={{ width: "100%",height:"170px", padding: "5px", border: "0.5px solid black", fontStyle:"italic" }}
          />
        </div>
        <button
                style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                    fontStyle: "italic",
                    backgroundColor: "#893694",
                    zIndex: "999",
                    float: "right",
                    marginTop: "15px",
                }}
                onClick={() => sendSms(description)}
                
        >
                <i className="fas fa-edit" style={{ marginRight: "5px" }}></i> Gönder
         </button>

            </div>
        </div>
        </div>
      </>
  );
}

export default AddSms;
