import React, { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getUser,
  getToken
} from "../service/AuthService";
import { baseUrl } from '../config/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function EditProduct() {
  const navigate = useNavigate();
  const location = useLocation();
 // const initialProduct = location.state && location.state.product ? location.state.product : {};
  const initialProduct = location.state && location.state.product ? location.state.product : { id: 10, name: "", price: 0, categoryId: 0 };

  const [product, setProduct] = useState(initialProduct);
  const [productId, setProductId] = useState(product.id);
  const [categories, setCategories] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
console.log("product",product)

  const token = getToken();

  const firebaseConfig = {
    apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
    authDomain: "monjardin-7cc13.firebaseapp.com",
    projectId: "monjardin-7cc13",
    storageBucket: "monjardin-7cc13.appspot.com",
    messagingSenderId: "81286471664",
    appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
    measurementId: "G-5BW4TRMJDR"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const storage = getStorage();


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const stockNumber = parseInt(value, 10);
    //console.log("stockNumber",stockNumber); 
    setStock(Number(stockNumber));

    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };
  
  function handleFetchError(error) {
    if (error.response && error.response.status === 500) {
      return <p>Daha sonra tekrar deneyiniz.</p>;
    } else {
      return <p>Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</p>;
    }
  }

    const fetchCategoryList = async () => {
      try {
        const response = await fetch(baseUrl+`api/Category/GetMainCategories`);
        if (!response.ok) {
          throw new Error('Kategori listesi getirilemedi. Lütfen daha sonra tekrar deneyin.');
        }
        const data = await response.json();
       // console.log("cateogryyyyyyy",data.data)
        const categoryData= data.data;
       // console.log("category",categoryData);
        setCategories(categoryData);
        //setFilteredProducts(categoryData);
      } catch (error) {
       // console.error(error);
        const errorMessage = handleFetchError(error);
       // alert('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        //console.log(errorMessage);
      }
    };


  const resizeImage = (file, maxWidth, maxHeight) => new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let width = img.width;
      let height = img.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
  
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.7); // 0.7 kalite oranı, ayarlayabilirsiniz
    };
  });


  const handleKaydet =async () => {
    if(product.tax < 0 || product.price <=0)
    {
        toast.error("Kdv ve Fiyat 0 dan büyük olmalıdır", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return null;
    }

   
    let downloadURLs = [];
    let downloadURLsString = "";
    const maxFileSizeInMB = 1;
    const maxWidth = 1024; 
    const maxHeight = 1024;
    
    
    if (selectedImages) {
    
      for (const image of selectedImages) {
        try {
          const resizedBlob = await resizeImage(image, maxWidth, maxHeight);
  
          if (resizedBlob.size / 1024 / 1024 <= maxFileSizeInMB) {
            const storageRef = ref(storage, "images/" + image.name);
            const snapshot = await uploadBytes(storageRef, resizedBlob);
            const downloadURL = await getDownloadURL(snapshot.ref);
            downloadURLs.push(downloadURL);
            console.log("Resim başarıyla yüklendi. URL:", downloadURL);
          } else {
            console.error("Resim boyutu belirlenen sınırdan büyük:", image.name);
          }
        } catch (error) {
          console.error("Resim yükleme hatası:", error);
        }
      }
  
      if (downloadURLs.length === selectedImages.length) {
         downloadURLsString = downloadURLs.join(' ');
        console.log("downloadURLs string:", downloadURLsString);
        
        /*
        toast.success("Tüm resimler başarıyla yüklendi.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        */
      } else {
        toast.error("Bazı resimler yüklenemedi.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }
    }

    console.log("dowloadurls",downloadURLs);

  if(!selectedImages && product.fileResponses.length===0)
  {
    toast.error("Lütfen resim ekleyiniz.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
    return null;
  }
  
  const { fileResponses,labelProducts,productDiscountInfo,categoryName, ...newProduct } = product;
  const stockNew = stock || 0;

  const newProductLast = {
    ...product,
    stock: parseInt(stockNew, 10),
  };

  console.log(JSON.stringify(newProductLast));
    const token = getToken();
 
  fetch(baseUrl+"api/Product/UpdateProduct", {
      method: "PUT",
      headers: {
        "Authorization":  `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newProductLast),
    })
      .then((response) => response.json())
      .then((data) => {
       
        if(data.success)
        {
           toast.success('Değişiklikler başarıyla kaydedilmiştir.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setTimeout(() => {
          navigate("/AdminProductList");
        }, 3000);
      }
        else{
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
        
       
        if (selectedImages) {
        fetch(baseUrl+"api/ProductFile/UpdateProductFile", {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        
          body: JSON.stringify({ id: product.fileResponses[0].id, productId:product.id, fileUrl: downloadURLsString  }),
        })
          .then((response) => response.json())
          .then((responseData) => {
            if(responseData.success)
            {
              toast.success(responseData.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
            else{
              toast.error(responseData.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
           // console.log("foto gitti",responseData);
          })
          .catch((error) => {
           // console.error(error);
          });
        }
        else{
         // console.log("Yeni foto yok")
        }
      })
      .catch((error) => {
        //console.error(error)
        const errorMessage = handleFetchError(error);
       // console.log(errorMessage);
      });
      
      
  };

  const [stock, setStock] = useState(product.stock);

  const handleIncrement = () => {
    setStock(stock + 1);
    handleInputChange({ target: { name: 'stock', value: stock + 1 } });
  };

  const handleDecrement = () => {
    if (stock > 0) {
      setStock(stock - 1);
      handleInputChange({ target: { name: 'stock', value: stock - 1 } });
    }
  };

  const handleDelete = async (id) => {
   // console.log("product id", id);
  
    fetch(baseUrl+`api/Product/DeleteProduct/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data",data);
       if(data.success)
            {
              toast.success(data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
              setTimeout(() => {
                navigate("/AdminProductList");
              }, 3000);
            }
            else{
              toast.error(data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }

       
      })
      .catch((error) => {
      //  console.error(error)
        const errorMessage = handleFetchError(error);
      //  console.log(errorMessage);
      });
  };
  
  const fetchProduct = async (id) => {
    try {
     // console.log("id", id)
      const response = await fetch(baseUrl+`api/Product/GetProductDetailByProductId/${id}`);
      if (!response.ok) {
        throw new Error('Kategori listesi getirilemedi. Lütfen daha sonra tekrar deneyin.');
      }
      const data = await response.json();
     // console.log("data", data.data)
      const productData = data.data;
      setProduct(productData);
    } catch (error) {
     // console.error(error);
      const errorMessage = handleFetchError(error);
     //alert('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'); 
     // console.log(errorMessage);
    }
  };
  
  useEffect(() => {
    fetchProduct(productId);
    fetchCategoryList();
  }, [productId]);
  
  const confirmDelete = () => {
    const result = window.confirm("Ürünü Silmek İstediğinize Emin Misiniz?");
    if (result) {
      handleDelete(productId);
    }
  };
  
  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya yönlendirir
  };
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);

    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
  };



  return(
    <div style={{ marginTop:"50px", paddingLeft:"50px", paddingRight:"50px" }}>
       <ToastContainer />
       <h1  className='baslik'>MJ Çiçek</h1>

        {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}

                    {product && (
                    <>
                    <div style={{display:"flex"}} className="add-product-area">
                    <div>
      {previewImages.length > 0 ? (
        previewImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Preview ${index}`}
            style={{ width: "100px", marginRight: "10px" }}
          />
        ))
      ) : product.fileResponses && product.fileResponses.length > 0 ? (
        product.fileResponses.map((fileResponse, index) => (
          <img
            key={index}
            src={fileResponse.fileUrl}
            alt={product.name}
            width={300}
            height={300}
            style={{ marginRight: "10px" }}
          />
        ))
      ) : null}

      <input
        type="file"
        onChange={handleImageUpload}
        multiple
      />
    </div>
             
            <div style={{ display:"block",marginLeft: "100px" }} className="add-product-area-items">
            
            <div style={{paddingTop:"20px"}}> 
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Adı:</span>
              <input
                type="text"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                className="edit-input-area"
              />
              </div>
              <hr/>
        
              <div style={{paddingTop:"20px"}}>      
                <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>KDV Oranı:</span>
              <input
                type="number"
                name="tax"
                value={product.tax}
                onChange={handleInputChange}
                className="edit-input-area"
                min={0}
              /></div>
                <hr/>

              <div style={{paddingTop:"20px"}}>
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Rengi:</span>
              <input
                type="text"
                name="color"
                value={product.color}
                onChange={handleInputChange}
                className="edit-input-area"
              /></div>
              <hr/>
               
        
              <div style={{paddingTop:"20px"}}> 
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Fiyat:</span>
              <input
                type="number"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                className="edit-input-area"
                min={0}
              />
               </div>
               <hr/>


               <div style={{ paddingTop: "20px" }}>
           <span style={{ fontStyle: "italic", fontFamily: "Times New Roman" }}>Kategori:</span>
           <select
              name="categoryId"
              value={product.categoryId}
              onChange={handleInputChange}
              className="edit-input-area"
            >
              <option value="">Kategori Seçin</option>
              {categories.length === 0 ? (
                <option disabled>Categories is empty</option>
              ) : (
                categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))
              )}
            </select>

           </div>
           <hr />
        
        
           <div style={{ paddingTop: "20px"}}>
              <span style={{ fontStyle: "italic", fontFamily: "Times New Roman" }}>Stok Sayısı:</span>
            <div style={{ display: "flex"}}>
              <button  style={{ fontStyle: "italic", background: "white", color:"black", width:"80px",height:"40px",fontSize:"15px", border:"1px solid black" }} onClick={handleIncrement}>+</button>
              <input
                type="number"
                name="stock"
                value={stock}
                onChange={handleInputChange}
                className="edit-input-area"
                min={0}
                style={{ width:"80px",height:"40px", textAlign:"center", border:"1px solid black"  }}
              />
              <button  style={{ fontStyle: "italic", background: "white" ,color:"black", width:"80px",height:"40px",fontSize:"17px" , border:"1px solid black"}} onClick={handleDecrement}>-</button>
              </div>
              <hr />
            </div>


           
          </div>
          </div>
            <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Açıklaması:</div>
              <textarea
                type="text"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                style={{ padding:"10px", width:"100%" }}
              />
          <div style={{display:"flex",float:"right", marginTop:"30px", marginBottom:"30px"}}>
            <button  onClick={() => confirmDelete(product.id)} className="save-button" style={{marginRight:"20px"}}>Sil</button>
            <button onClick={handleKaydet} className="save-button" style={{ }}>Kaydet</button>
          </div>
        </>
    )}
  </div>
 


  );

 
}


/*
 <div style={{padding:"20px"}}> 
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Adedi:</span>
              <input
                type="number"
                name="stock"
                value={product.stock}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>
               <hr/>


            <button  onClick={() => confirmDelete(product.id)} className="save-button" style={{float:"right", marginTop:"30px", marginRight:"20px"}}>Sil</button>

<div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Id:</div>
            <input
              type="text"
              name="id"
              value={product.id}
              onChange={handleInputChange}
              className="edit-input-area"
              />

              </div>


<div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>QR :</div>
              <input
                type="text"
                name="barcode"
                value={product.barcode}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>

                 <div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Kategori:</div>
              <input
                type="text"
                name="categoryName"
                value={product.categoryName}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>
*/