import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../config/Constants';
import { getToken } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AdminAllNotification() {
  const [notificationList, setNotificationList] = useState([]);
  let token = getToken();

  const fetchShipmentList = async () => {
    try {
      const requestOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await fetch(baseUrl+`api/Notification/GetNotificationsForAdmin`, requestOptions);
      const data = await response.json();

      const notificationData= data.data;
      setNotificationList(notificationData);  
    } catch (error) {
     // console.error(error);
    }
  };

  useEffect(() => {
    fetchShipmentList();
  }, []);
 
  
  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); 
  };

  const handleDeleteClick = (notification) => {
     fetch(baseUrl+`api/Notification/DeleteNotification?id=${parseInt(notification)}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
        if(data.success)
        {
            toast.success(data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            fetchShipmentList();
            return; 
         }
         else{
          toast.error('Lütfen Daha Sonra Tekrar Deneyiniz.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return; 
         }
        })
        .catch((error) => {
          toast.error('Lütfen Daha Sonra Tekrar Deneyiniz.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return; 
        });
  };

return (
  <div  style={{ margin: "50px" }}>
                <ToastContainer />
   <h1  className='baslik'>MJ Çiçek</h1>

        {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}
      
    <button
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 10px",
        borderRadius: "5px",
        backgroundColor: "#893694",
        border: "1px solid gray",
        boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
        right: "20px",
        zIndex: "999",
        float:"right",
        marginTop:"30px"
      }}
       onClick={() => navigate("/adminaddnotification")}
    >
      <i className="fas fa-edit" style={{ marginRight: "5px" }}></i> Yeni Bildirim Ekle
    </button>
    <div style={{marginTop:"50px"}}>

      {notificationList && (
      <table className='table table-light'>
      <thead>
      </thead>
      <tbody>
        {notificationList.map((notification) => (
        
          <React.Fragment key={notification.id}>
          
            <tr style={{ borderBottom: "1px solid #ccc"}}>
            
              <td style={{verticalAlign:"middle",width:"55%"}}>{notification.title}</td>
              <td style={{ verticalAlign: "middle" }}>{formatDate(notification?.firstTransactionDate)}</td>

              
              <td style={{verticalAlign:"middle"}}>  <button
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 10px",
                      borderRadius: "5px",
                      backgroundColor: "#893694",
                      border: "1px solid gray",
                      boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
                      right: "20px",
                      zIndex: "999",
                      float:"right",
                      marginTop:"50px"
                    }}
                    onClick={() => handleDeleteClick(notification.id)}
                  >
                    <i className="fas fa-edit" style={{ marginRight: "5px" }}></i>Gizle
                  </button></td>
            
            </tr>
            
          </React.Fragment>
        ))}
        
      </tbody>
    
    </table>
      )}
    </div>
</div>
)}