import React, { useState,useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getUser,getToken,resetUserSession,getUserInfo } from "../service/AuthService";
import { baseUrl } from '../config/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AdminAddProduct() {
  const navigate = useNavigate();

  const [product, setProduct] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
 // const [previewImage, setPreviewImage] = useState(null);
 const [previewImages, setPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);

    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
  };


  


  const token = getToken();
  const firebaseConfig = {
    apiKey: "AIzaSyBVljeCIm_rhZBx0522TXkNa4G4ufKoMLY",
    authDomain: "monjardin-7cc13.firebaseapp.com",
    projectId: "monjardin-7cc13",
    storageBucket: "monjardin-7cc13.appspot.com",
    messagingSenderId: "81286471664",
    appId: "1:81286471664:web:51a5c1de2c61b4551b4735",
    measurementId: "G-5BW4TRMJDR"
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app); 

  const storage = getStorage();

  /*
  const handleImageUpload = async(event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };*/
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let processedValue = value;
  
    
    if ( name === 'tax' || name === 'categoryId'  ) {
      processedValue = parseInt(value, 10);
      if (isNaN(processedValue)) {
        processedValue = '';
      }
    }
  
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: processedValue,
    }));
  };
  
  function handleFetchError(error) {
    if (error.response && error.response.status === 500) {
      return <p>Daha sonra tekrar deneyiniz.</p>;
    } else {
      return <p>Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</p>;
    }
  }
  
  const fetchCategoryList = async () => {
      try {
        const response = await fetch(baseUrl+`api/Category/GetMainCategories`);
        if (!response.ok) {
          throw new Error('Kategori listesi getirilemedi. Lütfen daha sonra tekrar deneyin.');
        }
        const data = await response.json();
      
        const categoryData= data.data;
     
        setCategories(categoryData);
       
      } catch (error) {
     
        const errorMessage = handleFetchError(error);
      
      }
  };

  const resizeImage = (file, maxWidth, maxHeight) => new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
  
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let width = img.width;
      let height = img.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
  
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.7); // 0.7 kalite oranı, ayarlayabilirsiniz
    };
  });

  const handleKaydet = async (event) => {
    event.preventDefault();
    const requiredFields = ['name', 'tax', 'color', 'price', 'categoryId', 'description'];
    const isEmptyField = requiredFields.some((field) => {
      const value = product[field];
      return value === undefined || value === null || value === "";
    });
  
    if (isEmptyField) {
      toast.error('Lütfen Tüm Alanları Doldurunuz.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
     
      const maxFileSizeInMB = 1;
      const maxWidth = 1024; 
      const maxHeight = 1024;
      
      let downloadURLs = [];
      let downloadURLsString = "";
      if (selectedImages) {
      
        for (const image of selectedImages) {
          try {
            const resizedBlob = await resizeImage(image, maxWidth, maxHeight);
    
            if (resizedBlob.size / 1024 / 1024 <= maxFileSizeInMB) {
              const storageRef = ref(storage, "images/" + image.name);
              const snapshot = await uploadBytes(storageRef, resizedBlob);
              const downloadURL = await getDownloadURL(snapshot.ref);
              downloadURLs.push(downloadURL);
              console.log("Resim başarıyla yüklendi. URL:", downloadURL);
            } else {
              console.error("Resim boyutu belirlenen sınırdan büyük:", image.name);
            }
          } catch (error) {
            console.error("Resim yükleme hatası:", error);
          }
        }
    
        if (downloadURLs.length === selectedImages.length) {
           downloadURLsString = downloadURLs.join(' ');
          console.log("downloadURLs string:", downloadURLsString);
          
          /*
          toast.success("Tüm resimler başarıyla yüklendi.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          */
        } else {
          toast.error("Bazı resimler yüklenemedi.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          return;
        }
      }

      console.log("dowloadurls",downloadURLs);
      const { fileResponses, labelProducts, productDiscountInfo, categoryName, ...newProduct } = product;
      const updatedProduct = {
        ...newProduct,
        stock: 0,
      
      };
     // console.log("newww", updatedProduct);
  
      fetch(baseUrl + "api/Product/CreateProduct", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProduct), // Güncellenmiş ürün bilgilerini gönderin
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("İç Sunucu Hatası");
          }
          return response.json();
        })
        .then((data) => {
        //  console.log("dd", data);
          toast.success('Değişiklikler başarıyla kaydedilmiştir.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setTimeout(() => {
            navigate("/AdminProductList");
          }, 3000);
  
          fetch(baseUrl + "api/ProductFile/CreateProductFile", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productId: data.data, fileUrl: downloadURLsString }),
          })
            .then((response) => response.json())
            .then((responseData) => {
            //  console.log("foto gitti", responseData);
            })
            .catch((error) => {
           //   console.error(error);
            });
        })
        .catch((error) => {
          toast.error('Lütfen daha sonra tekrar deneyiniz.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          throw error; // Hata
        });


    }


  };
  
  useEffect(() => {
    fetchCategoryList();
  }, []);
 

  return(
  
    <div style={{ marginTop:"50px", paddingLeft:"50px", paddingRight:"50px" }}>
       <h1  className='baslik'>MJ Çiçek</h1>
        {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}
        <>
      <ToastContainer />
        <div style={{display:"flex"}}>
          
          <div>
            <input type="file" onChange={handleImageUpload} multiple />
            <div className="image-preview-container">
              {previewImages.map((image, index) => (
                <img 
                  key={index}
                  src={image} 
                  alt={`Preview ${index}`}
                  style={{ width: "200px", margin: "10px" }} 
                />
              ))}
            </div>
          </div>


            <div style={{ display:"block",marginLeft: "100px" }}>
            <div style={{paddingTop:"20px"}}> 
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Adı:</span>
              <input
                type="text"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                className="edit-input-area"
              />
              </div>
              <hr/>
              
              <div style={{paddingTop:"20px"}}>
              <span style={{fontStyle:"italic", fontFamily:"Times New Roman"}}>KDV Oranı:</span>
              <input
                type="number"
                name="tax"
                value={product.tax >= 0 ? product.tax : ''}
                min="0"
                onChange={handleInputChange}
                className="edit-input-area"
              />
            </div>
            <hr/>
            

             <div style={{ paddingTop: "20px" }}>
                <span style={{ fontStyle: "italic", fontFamily: "Times New Roman" }}>Ürün Rengi:</span>
                <select
                  name="color"
                  value={product.color}
                  onChange={handleInputChange}
                  className="edit-input-area"
                >
                  <option value="kırmızı">Kırmızı</option>
                  <option value="mavi">Mavi</option>
                  <option value="yeşil">Yeşil</option>
                  <option value="sarı">Sarı</option>
                  <option value="pembe">Pembe</option>
                  <option value="lila">Lila</option>
                  <option value="mor">Mor</option>
                  <option value="kahverengi">Kahverengi</option>
                  <option value="siyah">Siyah</option>
                  <option value="beyaz">Beyaz</option>
                  <option value="turuncu">Turuncu</option>
                  <option value="gri">Gri</option>
                  <option value="lacivert">Lacivert</option>
                
                </select>
              </div>

              <hr/>
           
              <div style={{paddingTop:"20px"}}>
              <span style={{fontStyle:"italic", fontFamily:"Times New Roman"}}>Fiyat:</span>
              <input
                type="number"
                name="price"
                value={product.price >= 0 ? product.price : ''}
                min="0"
                onChange={handleInputChange}
                className="edit-input-area"
              />
            </div>
           
            
             <hr/>
             


               <div style={{ paddingTop: "20px" }}>
           <span style={{ fontStyle: "italic", fontFamily: "Times New Roman" }}>Kategori:</span>
           <select
            type="number"
              name="categoryId"
             value={product.categoryId}
              onChange={handleInputChange}
              className="edit-input-area"
            >
              <option value="">Kategori Seçin</option>
              {categories.length === 0 ? (
                <option disabled>Categories is empty</option>
              ) : (
                categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))
              )}
            </select>

           </div>
                
          </div>
          </div>
            <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Açıklaması:</div>
              <textarea
                type="text"
                name="description"
                value={product.description}
                onChange={handleInputChange}
                style={{ padding:"10px", width:"100%" }}
              />
           
       
            <button onClick={handleKaydet} className="save-button" style={{float:"right", marginTop:"30px"}}>Yeni Ürün Ekle</button>
         
        </>
    
    
    </div>
 


  );

 
}


/*
 <div style={{padding:"20px"}}> 
              <span style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Adedi:</span>
              <input
                type="number"
                name="stock"
                value={product.stock}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>
               <hr/>


            <button  onClick={() => confirmDelete(product.id)} className="save-button" style={{float:"right", marginTop:"30px", marginRight:"20px"}}>Sil</button>

<div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Ürün Id:</div>
            <input
              type="text"
              name="id"
              value={product.id}
              onChange={handleInputChange}
              className="edit-input-area"
              />

              </div>


<div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>QR :</div>
              <input
                type="text"
                name="barcode"
                value={product.barcode}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>

                 <div style={{padding:"20px"}}> 
              <div style={{fontStyle:"italic",fontFamily:"Times New Roman"}}>Kategori:</div>
              <input
                type="text"
                name="categoryName"
                value={product.categoryName}
                onChange={handleInputChange}
                className="edit-input-area"
              />
               </div>
*/