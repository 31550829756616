import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getToken } from "../service/AuthService";
import { ToastContainer, toast } from 'react-toastify';
import { baseUrl } from '../config/Constants';
import 'react-toastify/dist/ReactToastify.css';


function AddNotification() {
 const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
 
  function showToastSuccess(message, duration = 2000) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  function showToastError(message, duration = 3000) {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  const sendNotification = async (title, description) => {
    if (title.length > 30) {
      toast.error('Başlık maksimum 30 karakter olmalı.',{
        position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      });

      return;
    }
  
    if (description.length > 120) {
      toast.error('Metin maksimum 120 karakter olmalı.',{
        position: toast.POSITION.TOP_CENTER,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      });
      return;
    }
  
    const requestData = {
      title: title,
      description: description,
    };  
    const token = getToken();
  
    try {
      const response = await fetch(baseUrl + "api/Notification/SendNotification", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        showToastSuccess('Bildirim başarıyla gönderilmiştir.');
        setTitle("");
        setDescription("");
        setTimeout(() => {
          navigate("/adminallnotification");
        }, 2000);
  
      } else {
        showToastError("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
      }
    } catch (error) {
      showToastError("Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.");
    }
  };

  const handleGoBack = () => {
    navigate(-1); 
  };

  return (
    <>
    <ToastContainer />
         {/*  <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button> */}


        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "400px" }}>
            <h1 className='baslik' style={{ textAlign: "center" }}>MJ Çiçek</h1>
           
    
            <div style={{ display: "vertical", marginTop:"100px",justifyContent: "center", alignItems: "center", height: "100vh" }}>
      
        <div style={{ marginBottom: "20px" }}>
    
          <input
          className="titleinput"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)} 
            placeholder="Başlık giriniz."
            style={{ width: "100%",height:"70px", padding: "10px", border: "0.5px solid black" , fontStyle:"italic" }}
          />
        </div>
    
        <div style={{ marginBottom: "20px" }}>
          <textarea
            className="contentinput"
            value={description}
            onChange={(e) => setDescription(e.target.value)} 
            placeholder="Metin giriniz."
            style={{ width: "100%",height:"170px", padding: "5px", border: "0.5px solid black", fontStyle:"italic" }}
          />
        </div>
        <button
  style={{
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    fontStyle: "italic",
    backgroundColor: "#893694",
    zIndex: "999",
    float: "right",
    marginTop: "15px",
  }}
  onClick={() => sendNotification(title, description)}
  
>
  <i className="fas fa-edit" style={{ marginRight: "5px" }}></i> Gönder
</button>

      </div>
      </div>
      </div>
      </>
  );
}

export default AddNotification;
