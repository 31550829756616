import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from 'react-router-dom';
//import Admincard from './Admincard';
import { getToken } from "../service/AuthService";
import { baseUrl } from '../config/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditShipment() {
  const navigate = useNavigate();
  const token = getToken();
  const [district, setDistrict] = useState([]);
  const [quarter, setQuarter] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const location = useLocation();
  const [shipment, setShipment] = useState(location.state.shipment || { shippingFee: '', districtId: '', quarterId: '', sameDayShipping: false });
  const fetchDistricts = async () => {
    try {
      const response = await fetch(baseUrl+`api/Quarter/GetQuartersByDistrictGroup`);
      if (!response.ok) {
        throw new Error('Bölgeler listesi getirilemedi.');
      }
      const data = await response.json();
    
      const districtData= data.data;
  
      setDistrict(districtData);
    
    } catch (error) {
      
    //  console.error("Bölgeler getirlirken hatayla karşılaşıldı.");
    }
};

const fetchGetShipmentById= async () => {
    try {
      const response = await fetch(baseUrl+`api/Shipment/GetShipmentById/${shipment}`);
      if (!response.ok) {
        throw new Error('Kargo tutarının detayları getirilemedi');
      }
      const data = await response.json();
    
      const shipmentData= data.data;
  
      setShipment(shipmentData);

      setSelectedDistrict(shipmentData.districtId);

      fetch(baseUrl+`api/Quarter/GetQuartersByDistrictGroup`).then(districtResponse => districtResponse.json())
      .then(districtData => { 

        const districts= districtData.data;

        setDistrict(districts);

        const selectedDistrictObject = districtData.data.find((d) => d.districtId === shipmentData.districtId);
        setQuarter(selectedDistrictObject?.quarterResponseModels || []);
        setSelectedQuarter(shipmentData.quarterId);
      });
    
    } catch (error) {
      
    //  console.error("Bölgeler getirlirken hatayla karşılaşıldı.");
    }
};

function showToastError(message, duration = 3000) {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
}


function showToastSuccess(message, duration = 2000) {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  });
}

const handleSelectDistrict = (event) => {
    const selectedDistrict = event.target.value;
      setSelectedDistrict(selectedDistrict);
        if(parseInt(selectedDistrict)>0)
        {
          const selectedDistrictObject = district.find((district) => district.districtId === parseInt(selectedDistrict));
          setQuarter(selectedDistrictObject.quarterResponseModels);
          setSelectedQuarter('');
        }
         else{
          setSelectedQuarter('');
          setQuarter([]);
        }
};

const handleSelectQuarterChange = (event) => {
    const selectedQuarter = event.target.value;
    setSelectedQuarter(selectedQuarter);
  };

  
  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya yönlendirir
  };

  useEffect(() => 
  {
    fetchDistricts();
    fetchGetShipmentById();
  },[]);

  const handleInputChange = (e) => {
    setShipment({
      ...shipment,
      [e.target.name]: e.target.value,
    });
  };

  const handleKaydet =async () => {

    const updateShipment = {
      ...shipment,
      districtId: parseInt(selectedDistrict),
      quarterId: parseInt(selectedQuarter),
      sameDayShipping: true
    }; 


    const requiredFields = ['shippingFee', 'districtId', 'quarterId'];
    const isNonShipmentEmpty = requiredFields.some((field) => {
      const value = updateShipment[field];
      return value === undefined || value === null || value === '' || (typeof value === 'number' && isNaN(value));
    });

    if (isNonShipmentEmpty) {
      showToastError('Lütfen tüm alanları doldurunuz.');
      return;
    }
  const token = getToken();
  fetch(baseUrl+"api/Shipment/UpdateShipment", {
      method: "PUT",
      headers: {
        "Authorization":  `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateShipment),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.success)
        {
        showToastSuccess('Değişiklikler başarıyla kaydedilmiştir.');
        setTimeout(() => {
          navigate("/adminshipmentlist");
        }, 2000);}
        else{
          toast.error(data.message??"Bilinmeyen bir hata ile karşılaşıldı", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };
return (
    <div style={{ margin: "50px" }}> 
                <ToastContainer />
    <h1  className='baslik'>MJ Çiçek</h1>
        <button onClick={handleGoBack} className='back-button'><img src="/images/back-button.png" alt="" width={40} height={30}/></button>

        <div style={{marginLeft:"10%"}}>

    <select
        name="districtId"
        value={selectedDistrict}
        onChange={handleSelectDistrict}
        className="add-shipment-form" 
        >
        <option value="">İlçe Seçiniz</option>
            {district.length === 0 ? (
            <option disabled>İçe bulunamadı.</option>
            ) : (
            district.map(d => (
                <option key={d.districtId} value={d.districtId}>
                {d.districtName}
                </option>
            ))
            )}
        </select>

        <select
        name="quarterId"
        value={selectedQuarter}
        onChange={handleSelectQuarterChange}
        className="add-shipment-form"
        >
        <option value="">Mahalle Seçiniz</option>
            {quarter.length === 0 ? (
            <option disabled>Mahalle bulunamadı.</option>
            ) : (
            quarter.map(q => (
                <option key={q.id} value={q.id}>
                {q.name}
                </option>
            ))
            )}
        </select> 
        <div style={{alignItems: "center", position: "relative"}}>
          <input
            type="number"
            name="shippingFee"
            value={shipment.shippingFee || ''}            
            onWheel={(e) => e.preventDefault()}
            onChange={handleInputChange}
            className="add-shipment-form"
            placeholder="Kargo Ücreti Giriniz"
            style={{flex: "1", border: "none", borderBottom: "1px solid black", paddingRight: "50px",width:"80%"}}
          />
          <span style={{position: "absolute", top: "50%", right: "25%", transform: "translateY(-25%)", borderLeft: "1px solid black", paddingLeft: "5px",height:"68%",fontSize:"20px"}}>
            <span style={{marginLeft:"80px"}}>TL</span>
          </span>
        </div>
        <button className="save-button" style={{float:"right", marginTop:"30px",marginRight:"20%"}} onClick={handleKaydet}>Kaydet</button>

    </div>
    </div>
    );
  } 

  




export default EditShipment;